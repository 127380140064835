import './artSquare.scss';
import fallingGuy from './falling guy.png';
import squareBackground from './Square-Background.jpg';

import { useLayoutEffect } from 'react';
import { animated, useSpring } from 'react-spring';

const ArtSquare = ({activateSquares}) => {

  //console.log('art render', activateSquares)

  const [revealSquare1, setSquare1] = useSpring(() => ({
    fill: 'none'
  }))
  const [revealSquare2, setSquare2] = useSpring(() => ({
    fill: '#eeeeee', fillOpacity: 1.0
  }))
  const [revealSquare3, setSquare3] = useSpring(() => ({
    fill: '#eeeeee', fillOpacity: 1.0
  }))
  const [revealSquare4, setSquare4] = useSpring(() => ({
    fill: '#eeeeee', fillOpacity: 1.0
  }))
  const [revealSquare5, setSquare5] = useSpring(() => ({
    fill: '#eeeeee', fillOpacity: 1.0
  }))
  const [revealSquare6, setSquare6] = useSpring(() => ({
    fill: '#eeeeee', fillOpacity: 1.0
  }))
  const [revealSquare7, setSquare7] = useSpring(() => ({
    fill: '#eeeeee', fillOpacity: 1.0
  }))

  const easeOutCubic = x => (1 - Math.pow(1 - x, 3))

  const [fallingAnimation, setFalling] = useSpring(()=>({
    translateY: '-10%', translateX: '-50%'
  }))

  const delay = 400

  useLayoutEffect(() => {
    //
    //Activate
    activateSquares === 'activated' && setSquare2.start({
      to:{ fillOpacity: 0 },
      config: { duration: 0 },
      delay: 0 + delay
    })
  
    activateSquares === 'activated' && setSquare3.start({
      to:{ fillOpacity: 0 },
      config: { duration: 0 },
      delay: 800 + delay
    })
  
    activateSquares === 'activated' && setSquare4.start({
      to:{ fillOpacity: 0 },
      config: { duration: 0 },
      delay: 400 + delay
    })
    activateSquares === 'activated' && setSquare5.start({
      to:{ fillOpacity: 0 },
      config: { duration: 0 },
      delay: 1600 + delay
    })
    activateSquares === 'activated' && setSquare6.start({
      to:{ fillOpacity: 0 },
      config: { duration: 0 },
      delay: 2500 + delay
    })
    activateSquares === 'activated' && setSquare7.start({
      to:{ fillOpacity: 0 },
      config: { duration: 0 },
      delay: 2000 + delay
    })
  
  
  
    //Deactivate
    activateSquares === 'DEactivated' && setSquare2.start({
      to:{ fillOpacity: 1 },
      config: { duration: 0 },
      //delay: 0 + delay
    })
  
    activateSquares === 'DEactivated' && setSquare3.start({
      to:{ fillOpacity: 1 },
      config: { duration: 0 },
      //delay: 800 + delay
    })
  
    activateSquares === 'DEactivated' && setSquare4.start({
      to:{ fillOpacity: 1 },
      config: { duration: 0 },
      //delay: 400 + delay
    })
    activateSquares === 'DEactivated' && setSquare5.start({
      to:{ fillOpacity: 1 },
      config: { duration: 0 },
      //delay: 1600 + delay
    })
    activateSquares === 'DEactivated' && setSquare6.start({
      to:{ fillOpacity: 1 },
      config: { duration: 0 },
      //delay: 2500 + delay
    })
    activateSquares === 'DEactivated' && setSquare7.start({
      to:{ fillOpacity: 1 },
      config: { duration: 0 },
      //delay: 2000 + delay
    })


    activateSquares === 'activated' && setFalling.start({
      to: { translateY: '48%' },
      config: { easing: easeOutCubic, duration: 9000 },
      //loop: true,
      delay: 0 + delay
    })
    activateSquares === 'DEactivated' && setFalling.start({
      to: { translateY: '-10%' },
      config: { duration: 0 },
      //loop: true,
      //delay: 0 + delay
    })
  })

  return(
    <>
      <img className='artBackground' src={ squareBackground } />
      <animated.img className='fallingGuy' src={ fallingGuy } style={ fallingAnimation } />
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 1164 1164" id='artSquare'>
      <g id="Layer_1">
        <animated.polygon className="st0" points="831,742.6 755.1,795.8 670.4,855.1 557.9,694.4 718.5,581.9 	" style={ revealSquare4 } />
        <animated.polygon className="st0" points="782.9,1015.8 622.2,1128.3 397.2,806.9 557.9,694.4 670.4,855.1 729.7,939.8 	" style={ revealSquare6 }/>
        
          <rect x="757.1" y="507.9" transform="matrix(0.8192 -0.5736 0.5736 0.8192 -192.9483 600.0661)" className="st1" width="196.1" height="196.1"/>
        <animated.polygon className="st0" points="926.4,196.4 766.5,308.4 765.8,308.9 653.5,148.6 653.3,148.2 813.9,35.7 	" style={ revealSquare1 } />
        <animated.polygon className="st0" points="879.2,469.4 879.2,469.4 718.5,581.9 557.9,694.4 445.4,533.8 606,421.3 766.7,308.8 766.7,308.8 
          766.9,309 860.9,443.3 	"  style={ revealSquare3 }/>
        <animated.polygon className="st0" points="766.7,308.8 766.7,308.8 606,421.3 493.5,260.6 653.5,148.6 654.2,148.1 766.5,308.4 	" style={ revealSquare2 } />
        
          <rect x="210.7" y="411.6" transform="matrix(0.8192 -0.5736 0.5736 0.8192 -236.496 269.2877)" className="st2" width="196.1" height="196.1"/>
        <animated.polygon className="st0" points="557.9,694.4 397.2,806.9 303.1,672.6 284.7,646.3 445.4,533.8 	" style={ revealSquare5 } />
        <animated.polygon className="st0" points="509.7,967.6 349,1080.1 236.5,919.4 262.9,901 397.2,806.9 	" style={ revealSquare7 } />
        
          <rect x="690.7" y="816.1" transform="matrix(0.8191 -0.5736 0.5736 0.8191 -363.4953 582.81)" className="st3" width="103.5" height="103.4"/>
        
          <rect x="201" y="704.8" transform="matrix(0.8191 -0.5736 0.5736 0.8191 -400.1162 304.6295)" className="st3" width="164" height="164"/>
        <polygon className="st3" points="995.2,349.3 860.9,443.3 860.9,443.4 766.8,309 766.9,309 901.1,215 	" />
      </g>
      <g id="Layer_2">
        <path className="st4" d="M0,0v1164h1164V0H0z M879.2,469.4L879.2,469.4l112.5,160.7L831,742.6l-75.9,53.2l59.3,84.7l-84.7,59.3
          l53.2,75.9l-160.7,112.5L509.7,967.6L349,1080.1L236.5,919.4l26.3-18.4l-94.1-134.3l134.3-94.1l-18.4-26.3L172.2,485.6l160.7-112.5
          l112.5,160.7L606,421.3L493.5,260.6l160-112l-0.2-0.3L813.9,35.7l112.5,160.7l-160,112l0.2,0.3v0l0.2,0.2l134.3-94l94.1,134.3
          l-134.3,94L879.2,469.4z"/>
        <polygon className="st5" points="879.2,469.4 991.7,630.1 831,742.6 755.1,795.8 814.4,880.5 729.7,939.8 782.9,1015.8 622.2,1128.3 
          509.7,967.6 349,1080.1 236.5,919.4 262.9,901 168.8,766.7 303.1,672.6 284.7,646.3 172.2,485.6 332.9,373.1 445.4,533.8 
          606,421.3 493.5,260.6 653.5,148.6 653.3,148.2 813.9,35.7 926.4,196.4 766.5,308.4 766.7,308.8 766.7,308.8 766.9,309 901.1,215 
          995.2,349.3 860.9,443.3 879.2,469.4 	"/>
      </g>
      </svg>
  </>
  )
}

export default ArtSquare