import './App.scss';
import Small from './components/Small/Small';
import Large from './components/Large/Large';
import Incompatible from './components/Incompatible/Incompatible';

import { useMediaQuery } from 'react-responsive';

function App() {
  const isSmall = useMediaQuery({ query: '(max-width: 520px)' });
  return (
    <div className="App">
      { isSmall ? <Small /> : <Large /> }
      <Incompatible />
    </div>
  );
}

export default App;
