import './large.scss';
import Section2Large from '../Section2Large/Section2Large';
import HeroOverlay from '../HeroOverlay/HeroOverlay';
import FrontSlider from '../FrontSlider/FrontSlider';
import DesktopScrollHandler from '../DesktopScrollHandler/DesktopScrollHandler';

import { memo, useRef, useState, useLayoutEffect } from 'react';

const Large = () => {
  //console.log('large hero rendered')
  const [ backgroundStatus, setStatus ] = useState('initial')
  const [ isIntroFinished, setIntroStatus ] = useState(false)
  const [currentCTAValue, setCurrentCTAValue] = useState(null)
  const ctaTracker = useRef(null)
  const introTracker = useRef(null)

  useLayoutEffect(() => {
    if(currentCTAValue !== ctaTracker.current) ctaTracker.current = currentCTAValue
    //if(introTracker.current !== isIntroFinished) introTracker.current = isIntroFinished
  })

  const changeBackground = useRef((status) => {
    introTracker.current && setStatus(status)
    //setStatus(status)
  })

  const introFinished = useRef(() => { 
    introTracker.current = true
    setIntroStatus(true)
  })

  const handleCTAClick = useRef(() => {
    //setCurrentCTAValue(!ctaTracker)
    if(ctaTracker.current === null) setCurrentCTAValue(false)
    else if(ctaTracker.current) setCurrentCTAValue(false)
    else if(!ctaTracker.current) setCurrentCTAValue(true)
  })

  return(
    <div className='desktopHeroMain'>
      <DesktopScrollHandler handleDesktopBackground={ changeBackground.current } />
      <HeroOverlay isIntroFinished={ isIntroFinished } ctaTrigger={currentCTAValue} />
      <FrontSlider introIsFinished={introFinished.current} isIntroFinished={ isIntroFinished } />
      <div className='dummySpace'></div>
      <Section2Large ctaClicked={ handleCTAClick.current } backgroundStatus={ backgroundStatus } />
    </div>
  )
}


export default memo(Large)