import './largeWhaleLargeOcean.scss';
import fullLargeBody from '../../Ocean/LargeWhale/Full Large Body.svg';
import outerWrinkles from '../../Ocean/LargeWhale/Outer Wrinkles.svg';

import { memo, useState, useLayoutEffect } from 'react'
import { useSpring, animated } from 'react-spring'

const LargestWhale = ({isIntroFinished}) => {
  const easeOutCirc = (x) => (Math.sqrt(1 - Math.pow(x - 1, 2)))
  const easeInCirc = x => (1 - Math.sqrt(1 - Math.pow(x, 2)))
  const easeInCubic = x => x * x * x
  const easeOutSine = x => Math.sin((x * Math.PI) / 2)
  const easeInOutSine = x => -(Math.cos(Math.PI * x) - 1) / 2
  const easeInSine = x => (1 - Math.cos((x * Math.PI) / 2))
  const easeOutQuint = x => (1 - Math.pow(1 - x, 5))
  //const easeOutSine = x => Math.sin((x * Math.PI) / 2)
  const easeOutCubic = x => 1 - Math.pow(1 - x, 3)

  const regularTop = `M61.6,30C50.2,4.6,8.3,9.7,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z`
  const regularBottom = `M61.6,30C50.2,55.3,8.3,50.3,0.2,30c0.5,16.6,14,29.8,30.7,29.8S61.2,46.5,61.6,30z`
  const squintTop = `M61.6,30C53,19.1,9.3,18.9,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z`
  const squintBottom = `M61.6,30C51,43.4,10.3,41.7,0.2,30c0.5,16.6,14,29.8,30.7,29.8S61.2,46.5,61.6,30z`
  
  const slightlyTop = `M61.6,30C50.1,8.8,7,13.3,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z`
  const slightlyBottom = `M61.6,30C50.5,50.9,6.3,45.6,0.2,30c0.5,16.6,14,29.8,30.7,29.8S61.2,46.5,61.6,30z`
  
  const closedTop = `M61.6,30C61,29.9,0.4,30,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z`
  const closedBottom = `M61.6,30C60.4,30.1,1,30,0.2,30c0.5,16.6,14,29.8,30.7,29.8S61.2,46.5,61.6,30z`

  const tempInitialDelay = 4000

  const [irisAnimated, setIris] = useSpring(() => ({
    from: { x:18.6 },
  }))


  const [middleEyeAnimated, setMiddleEye] = useSpring(() => ({
    from: { translateX: '-50%', translateY: '-50%' },
    
  }))
  const [topEyeAnimated, setTopEye] = useSpring(() => ({
    from: { x: slightlyTop},
    
  }))
  const [bottomEyeAnimated, setBottomEye] = useSpring(() => ({
    from: { x: slightlyBottom},
  
  }))

  const [verticalAnimation, setVertAnimation] = useSpring(()=>({
    from: { translateY: '-270%' },
    
  }))
  const [horAnimation, setHorAnimation] = useSpring(()=>({
    from: { translateX: '1200%' },
    
  }))




  useLayoutEffect(() => {
    setHorAnimation.start({
      from: { translateX: '1200%' },
      to: [
        { translateX: '1199%',delay: 0, config: { duration: 2700 }},
        { translateX: '-43%', config: { duration: 2900, easing: easeOutCirc, precision: .001 } },
        { translateX: '-600%', config:{ duration: 2500 , easing: easeInCubic }, delay: 6150 },
        //{ translateX: '-8000%', config:{ duration: 2300 } },
      ],
      //delay: 2200,
      //reset: true,
      pause: isIntroFinished ? false : true
    })

    setVertAnimation.start({
      from: { translateY: '-270%' },
      to: [
        {translateY: '-269%', delay: 0, config: { duration: 2800 }},
        { translateY: '-65%', config:{ duration: 2800, easing: easeOutCirc } },
        { translateY: '-81%', config:{ duration: 2500, easing: easeOutSine }, delay:50 },
        { translateY: '-58%', config:{ duration: 3650, easing: easeInOutSine } },
        { translateY: '1725%', config:{ duration: 3400, easing: easeInCirc } },
        //{ translateY: '1725%', config:{ duration: 0 }, delay:1350 }
      ],
      pause: isIntroFinished ? false : true
      //delay: 2400,
      //reset: true,
    })

    setMiddleEye.start({
      from: { translateX: '-50%', translateY: '-50%' },
      to: [
        {
          translateX: '-70%',
          config: { duration: 200, precision: .001 },
          delay: tempInitialDelay + 2600 - 183,
        },
        {
          translateX: '-30%',
          config: { duration: 210,
            precision: .001, easing: easeOutSine },
          delay: 510,
        },
        {
          translateX: '-50%',
          config: { duration: 500, precision: .001, easing: easeOutSine },
          delay: 1400,
        },
  
      ],
      pause: isIntroFinished ? false : true,
    })

    setTopEye.start({
      from: { x: slightlyTop},
      to: [
        { 
          x: regularTop,
          config: { duration: 1500, precision: .001, easing: easeOutCubic },
          delay: tempInitialDelay + 500,
        },
        {
          x: closedTop,
          config: { duration: 180, precision: .001 },
          delay: 250,
        },
        {
          x: regularTop,
          config: { duration: 180, precision: .001 },
        },
        {
          x: closedTop,
          config: { duration: 150, precision: .001 },
          delay: 450,
        },
        {
          x: regularTop,
          config: { duration: 150, precision: .001 },
        },
        {
          x: closedTop,
          config: { duration: 150, precision: .001 },
          delay: 1250,
        },
        {
          x: regularTop,
          config: { duration: 150, precision: .001 },
        },
        {
          x: squintTop,
          config: { duration: 500, precision: .001, easing: easeOutQuint },
          delay: 1740,
        },
      ],
      pause: isIntroFinished ? false : true,
    })

    setBottomEye.start({
      from: { x: slightlyBottom},
    to: [
      { 
        x: regularBottom,
        config: { duration: 1500, precision: .001, easing: easeOutCubic },
        delay: tempInitialDelay + 500,
      },
      {
        x: closedBottom,
        config: { duration: 180, precision: .001 },
        delay: 250,
      },
      {
        x: regularBottom,
        config: { duration: 180, precision: .001 },
      },
      {
        x: closedBottom,
        config: { duration: 150, precision: .001 },
        delay: 450,
      },
      {
        x: regularBottom,
        config: { duration: 150, precision: .001 },
      },
      {
        x: closedBottom,
        config: { duration: 150, precision: .001 },
        delay: 1250,
      },
      {
        x: regularBottom,
        config: { duration: 150, precision: .001 },
      },
      {
        x: squintBottom,
        config: { duration: 500, precision: .001, easing: easeOutQuint },
        delay: 1740,
      },
      ],
      pause: isIntroFinished ? false : true,
    })

    setIris.start({
      from: { x:18.6 },
      to: [
        {
          x: 18.5,
          config: { precision: .001, duration: 700, easing: easeOutSine },
          //delay: tempInitialDelay + 700 - 183,
        },
        {
          x: 15.6,
          config: { precision: .001, duration: 600, easing: easeOutSine },
          //delay: tempInitialDelay + 700 - 183,
        },
        {
          x: 12.3,
          config: { precision: .001, duration: 370, easing: easeOutCubic },
          delay: 1370
        },
        {
          
          x: 15.6,
          config: { precision: .001, duration: 510, easing: easeOutSine  },
          delay: 1910,
        },
        
      ],
      delay: tempInitialDelay - 183 + 50,
      pause: isIntroFinished ? false : true
    })

    const timer = setTimeout(() => {
      setRestart(!restartAnimation)
    }, 16700);
    return () => clearTimeout(timer);
  })

  const onFocus = (isIntroFinished) => 
    isIntroFinished && 
    setRestart(!restartAnimation)
  
 
  useLayoutEffect(() => {
    window.addEventListener('focus', onFocus);

    //horizontalElement.addEventListener('animationstart', onAnimationFinished)
    // Specify how to clean up after this effect:
    return () => {
      //console.log('clean me up')
      window.removeEventListener('focus', onFocus);
      
    };
  }, []);


  const [ restartAnimation, setRestart ] = useState(false)

  return(
    <>
        <animated.div className='largestWhaleHorizontal' id='horizontal' style={ horAnimation } >
        <animated.div className='largeWhaleVertical' style={ verticalAnimation } >
          <div className='innerWhaleTest'>
            <img className='whaleSRC' src={ fullLargeBody } />
            <div className='outerEyeContainer'>
              


            <div className='eyeWrap'>
        <div className='eyeWrinklesWrap'>
          <img src={ outerWrinkles } />
        </div>
        <svg version="1.1" id="whitePart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	      viewBox="0 0 61.8 60">
          <ellipse className="st0" cx="30.9" cy="30" rx="30.7" ry="29.9"/>
        </svg>


      <animated.div className='middleEyePart' style={ middleEyeAnimated }>
        <div className='middleEyeWrap'>


          <svg version="1.1" id="bluePart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 37.3 37.3"  >
            <circle className="st0" cx="18.6" cy="18.6" r="18.6"/>
          </svg>



          <svg version="1.1" id="blackPart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" 
	          viewBox="0 0 37.3 37.3" >
              <animated.circle className="st0" id='circleSVG' cx="18.6" cy="18.6" r={ irisAnimated.x } />
          </svg>



          <svg version="1.1" id="reflectionPart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	          viewBox="0 0 37.3 37.3">
            <circle className="st0" cx="12.7" cy="8.9" r="4.6"/>
          </svg>
        </div>
      </animated.div>{/*End of middle eye part */}




      {/*
      <svg version="1.1" id="whaleEyeLid" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	      viewBox="0 0 61.8 60">
      
      <animated.path className="st0" d="M61.6,30C50.2,4.6,8.3,9.7,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z"/>
      <path className="st0" d="M61.6,30C50.2,55.3,8.3,50.3,0.2,30c0.5,16.6,14,29.8,30.7,29.8C47.6,59.8,61.2,46.5,61.6,30z"/>
      </svg>
      */}

      
      
      <svg version="1.1" id="whaleEyeLid" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	      viewBox="0 0 61.8 60">
      <animated.path className="st0" d={ topEyeAnimated.x } />

      {/*
      <animated.path className="st0" d={ eyeLidInter.to({
        range: [0,.16,.3,.32,.34,.44,.46,.48,.67,.69,.71,.97,1],
        output: [ slightlyBottom,regularBottom,regularBottom,closedBottom,regularBottom,regularBottom,closedBottom,regularBottom, regularBottom, closedBottom,regularBottom,regularBottom, squintBottom ]
      }).to(y=>`${y}`) } /> 
      */}
      <animated.path className="st0" d={ bottomEyeAnimated.x } /> 
      
      </svg>
    </div>






            </div>
          </div>
        </animated.div>
      </animated.div>
    </>
  )
}


export default memo(LargestWhale)