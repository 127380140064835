import './whale.scss';
import './eye.scss';

import tail from './Tail.svg';
import body from './Body.svg';
import frontFin from './Front Fin.svg';
import rearFin from './Rear Fin.svg';

import { memo, useRef, useEffect } from 'react';
import { useSpring, animated } from 'react-spring'

const Whale = () => {
  //console.log('whale rendered')

  const easeOutSine = (x) => (Math.sin((x * Math.PI) / 2))
  const easeInOutCubic = (x) => (x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2)

  //Whale animations
  const whaleRotationalMovement = useSpring({
  from: { rotate: '1deg', transformOrigin: '56% 26%' },
  to: { rotate: '-9deg', transformOrigin: '56% 26%' },
  config: { duration: 7000, },
  loop: { reverse: true },
  //pause: !isIntroFinished ? true : false
  })

  const whaleLeftFin = useSpring({
    from: { rotate: '0deg', transformOrigin: '22% 72%' },
    to: { rotate: '-16deg', transformOrigin: '22% 72%' },
    config: { duration: 2000, easing: easeOutSine},
    loop: { reverse: true },
    //pause: !isIntroFinished ? true : false
  })
  const whaleRearFin = useSpring({
    from: { rotate: '0deg', transformOrigin: '29% 71%' },
    to: { rotate: '-9deg', transformOrigin: '29% 71%' },
    config: { duration: 2500, },
    loop: { reverse: true },
    //pause: !isIntroFinished ? true : false
  })

  const whaleTail = useSpring({
    from: { rotate: '7deg', transformOrigin: '92% 85%' },
    to: { rotate: '-7deg', transformOrigin: '92% 85%' },
    config: { duration: 1800, easing: easeInOutCubic },
    loop: { reverse: true },
    delay: 300,
    //pause: !isIntroFinished ? true : false
  })
  
  const whaleVerticalMovement = useSpring({ 
  from: { translateY: '0%' },
  to: { translateY: '55%' },
  config: { duration: 10000, easing: easeInOutCubic },
  loop: { reverse: true },
  //pause: !isIntroFinished ? true : false

  })


  
  const regularTop = `M61.6,30C50.2,4.6,8.3,9.7,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z`
  const regularBottom = `M61.6,30C50.2,55.3,8.3,50.3,0.2,30c0.5,16.6,14,29.8,30.7,29.8S61.2,46.5,61.6,30z`
  
  const closedTop = `M61.6,30C61,29.9,0.4,30,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z`
  const closedBottom = `M61.6,30C60.4,30.1,1,30,0.2,30c0.5,16.6,14,29.8,30.7,29.8S61.2,46.5,61.6,30z`
  
  const [blink, setBlink] = useSpring(() => ({
    x:regularTop, y: regularBottom
  }))

  const blinkStart = () => setBlink.start({
    from: { x:regularTop, y: regularBottom },
    to: { x:closedTop, y: closedBottom },
    config: { duration: 250 },
    onRest: () => blinkT1(),
    delay: 1700
  })
  const blinkT1 = () => setBlink.start({
    from: { x:closedTop, y: closedBottom },
    to: { x:regularTop, y: regularBottom },
    config: { duration: 250 },
    onRest: () => blinkStart()
  })
    
  const whaleBodyTracker = useRef()
  const whaleTailTracker = useRef()
  const whaleFinTracker = useRef()

  const whaleBodyFinished = useRef(false)
  const whaleTailFinished = useRef(false)
  const whaleFinFinished = useRef(false)

  useEffect(() => {
    //Check if image is cached
    if(whaleBodyTracker.current.complete) whaleLoadComplete('body')
    if(whaleTailTracker.current.complete) whaleLoadComplete('tail')
    if(whaleFinTracker.current.complete) whaleLoadComplete('fin')

    setBlink.start({
      from: { x:regularTop, y: regularBottom},
      to: { x:closedTop, y: closedBottom },
      config: { duration: 250 },
      onRest: () => blinkT1(),
      delay: 1700
    })
  }, [])
  const whaleLoadComplete = (bodyPart) => {
    if(bodyPart === 'body') whaleBodyFinished.current = true
    if(bodyPart === 'tail') whaleTailFinished.current = true
    if(bodyPart === 'fin') whaleFinFinished.current = true
    const isWhaleFullyLoaded = whaleBodyFinished.current && whaleTailFinished.current && whaleFinFinished.current
    
    //Should work, but will use later
    //if(isWhaleFullyLoaded) smallWhaleLoaded()
    
  }


  return(
    <animated.div className='whale' style={ whaleRotationalMovement } >
      <animated.div className='whaleVerticalWrap' style={ whaleVerticalMovement } >

      <animated.img className='whaleRear'
          //onLoad={ () => !whaleFinFinished.current && whaleLoadComplete('fin') }
          //ref={ whaleFinTracker }
          src={ rearFin }
          style={ whaleRearFin }
        />
        
        <img className='whaleBody' 
          onLoad={ () => !whaleBodyFinished.current && whaleLoadComplete('body') } 
          ref={ whaleBodyTracker }
          src={ body }
        />
          
        <animated.img className='whaleTail' style={ whaleTail }
          onLoad={ () => !whaleTailFinished.current && whaleLoadComplete('tail') }
          ref={ whaleTailTracker }
          src={ tail }
        />
        
        <animated.img className='whaleFin' 
          onLoad={ () => !whaleFinFinished.current && whaleLoadComplete('fin') }
          ref={ whaleFinTracker }
          src={ frontFin }
          style={ whaleLeftFin }
        />
        
        <div className='smallEye'>
          <animated.svg version="1.1" id="whitePart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          viewBox="0 0 61.8 60">
            <ellipse className="st0" cx="30.9" cy="30" rx="30.7" ry="29.9"/>
          </animated.svg>
          <div className='middleEyePart'>
            <div className='middleEyeWrap'>
              <animated.svg version="1.1" id="smallBlackPart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
              viewBox="0 0 37.3 37.3" >
                <circle className="st0" cx="18.6" cy="18.6" r="18.6"/>
              </animated.svg>



              <animated.svg version="1.1" id="reflectionPart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 37.3 37.3">
                <circle className="st0" cx="12.7" cy="8.9" r="4.6"/>
              </animated.svg>
            </div>
          </div>


          <svg version="1.1" id="whaleEyeLid" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	      viewBox="0 0 61.8 60">
      <animated.path className="st0" d={ blink.x } />


      <animated.path className="st0" d={ blink.y } /> 
      
      </svg>
        </div>
        
        <div className='dotWrap'>
          <div className='dot'></div>
        </div>
        
      </animated.div>
    </animated.div>
  )
}

export default memo(Whale);