import './ocean.scss';
import oceanBackground from './Ocean Background.svg';
import oceanPlain from './OceanPlain.svg';
import sunRays from './Sun Rays.svg';
import Whale from './Whale/Whale';
import LargeWhale from './LargeWhale/LargeWhale';

import { memo } from 'react';
import { animated, useSpring } from 'react-spring';

const Ocean = ({isIntroFinished}) => {
  //console.log('ocean rendered')

  const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2)
  
   const [ sunRayAnimation, setSunRay ] = useSpring(()=>({
     rotate: '0deg', transformOrigin: 'top right'
   }))
  
  const sunRayStart = () => setSunRay.start({
    from: { rotate: '0deg', transformOrigin: 'top right' },
    to: { rotate: '8deg', transformOrigin: 'top right' },
    config: { duration: 4000, easing: easeInOutQuad },
    onRest: () => sunRayT1()
  })
  const sunRayT1 = () => setSunRay.start({
    from: { rotate: '8deg', transformOrigin: 'top right' },
    to: { rotate: '0deg', transformOrigin: 'top right' },
    config: { duration: 4000, easing: easeInOutQuad },
    onRest: () => sunRayStart()
  })


  /*
  const {sunRayInterpolation} = useSpring({
    from: { sunRayInterpolation: 0, transformOrigin: 'top right' },
    to: { sunRayInterpolation: 1, transformOrigin: 'top right' },
    config: { duration: 12000, easing: easeInOutQuad },
    loop: true,
    pause: isIntroFinished ? false : true
  })
  */

  const removeInitialOceanCover = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    pause: !isIntroFinished ? true : false,
    delay: 1000,
    config: { duration: 800 }
  })

  /*
  const smallWhaleReady = useRef(() => { 
    console.log('small whale fully loaded')
  })

  const largeWhaleReady = useRef(() => {
    console.log('large whale fully loaded')
  }) */



  isIntroFinished && sunRayStart()
  
  return(
    <div className='ocean'>
      <img className='oceanPlainLarge' src={ oceanPlain } />
      <div id='screenWrap'>
        <img className='oceanBackground' src={ oceanBackground } />
        
        <Whale />
        <animated.img className='sunRay' style={ sunRayAnimation } src={ sunRays } />
        <LargeWhale isIntroFinished={ isIntroFinished } />
        <animated.img className='initialOceanCover' src={ oceanPlain } style={ removeInitialOceanCover } />
        
      </div>

      
    </div>
  )
}

export default memo(Ocean);