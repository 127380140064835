import './largeWhale.scss';
import outerWrinkles from './Outer Wrinkles.svg';
import fullLargeBody from './Full Large Body.svg';

import { useState, useLayoutEffect, memo } from 'react';
import { useSpring, animated } from 'react-spring';

const LargeWhale = ({isIntroFinished}) => {
  //console.log('largewhale rendered')

  /*
  const eyeReady = useRef(() => { 
    console.log('eye fully loaded')
  })

  const largeWhaleTracker = useRef()
  const isLargeWhaleLoaded = useRef(false)
  */


  const easeOutCirc = (x) => (Math.sqrt(1 - Math.pow(x - 1, 2)))
  const easeInCirc = x => (1 - Math.sqrt(1 - Math.pow(x, 2)))
  const easeInCubic = x => x * x * x
  const easeOutSine = x => Math.sin((x * Math.PI) / 2)
  const easeInOutSine = x => -(Math.cos(Math.PI * x) - 1) / 2
  const easeInSine = x => (1 - Math.cos((x * Math.PI) / 2))

  const easeOutQuint = x => (1 - Math.pow(1 - x, 5))
  //const easeOutSine = x => Math.sin((x * Math.PI) / 2)
  const easeOutCubic = x => 1 - Math.pow(1 - x, 3)
  //const easeInOutSine = x => -(Math.cos(Math.PI * x) - 1) / 2
  //const easeInSine = x => 1 - Math.cos((x * Math.PI) / 2)

  /*
  const [largeWhaleVertical, setVertical] = useSpring(() => ({
    translateY: '-270%'
  }))
  const [largeWhaleHorizontal, setHorizontal] = useSpring(() => ({
    translateX: '1200%'
  }))


  const verticalT1 = () => setVertical.start({
    delay: 50,
    from: { translateY: '-65%' },
    to: { translateY: '-77%' },
    config: { duration: 2500, easing: easeOutSine },
    onRest: () => verticalT2()
  })
  const verticalT2 = () => setVertical.start({
    //delay: 2700,
    from: { translateY: '-77%' },
    to: { translateY: '-59%' },
    config: { duration: 3650, easing: easeInOutSine },
    onRest: () => verticalT3()
  })
  //11450
  
  
  const verticalT3 = () => setVertical.start({
    //delay: 2700,
    //delay: 500,
    from: { translateY: '-59%' },
    to: { translateY: '1725%' },
    config: { duration: 3400, easing: easeInCirc },
    onRest: () => verticalEnd()
  })
  const verticalEnd = () => setVertical.start({
    //from: { z: 0, translateY: '-270%' },
    //to: { z: 1, translateY: '-270%' },
    from: { z: 0, translateY: '1725%' },
    to: { z: 1, translateY: '1725%' },
    config: { duration: 0},
    
    delay: 1350,
    onRest: () => verticalStart()
  })
  
  


  const horizontalT1 = () => setHorizontal.start({
    delay: 6150,
    from: { translateX: '-43%' },
    to: { translateX: '-600%' },
    config: { duration: 2500 , easing: easeInCubic },
    onRest: () => horizontalT2()
  })
  const horizontalT2 = () => setHorizontal.start({
    //delay: 5850,
    from: { translateX: '-600%' },
    to: { translateX: '-8000%' },
    config: { duration: 2300 },
    onRest: () => horizontalStart()
    
  })
  
  
  const verticalStart = () => setVertical.start({
    delay: 2800,
    from: { translateY: '-270%' },
    to: { translateY: '-65%' },
    config: { duration: 2800, easing: easeOutCirc },
    onRest: () => verticalT1(),
    //onStart: () => console.log('vert start')
    //ref: verticalRef,
  })

  
  const horizontalStart = () => setHorizontal.start({
    delay: 1,
    from: { translateX: '1199%' },
    to: { translateX: '1200%' },
    config: { duration: 0 },
    onRest: () => horizontalT1Plus(),
    //onStart: () => console.log('horizontal start')
    //onStart: () => console.log("hor started")
    //cancel: true
    //ref: horizontalRef
  })

  const horizontalT1Plus = () => setHorizontal.start({
    delay: 2699,
    from: { translateX: '1200%' },
    to: { translateX: '-43%' },
    config: { duration: 2900, easing: easeOutCirc, precision: .001 },
    onRest: () => horizontalT1()
  })
  */
  
  
  //isIntroFinished && setHorizontal.start({cancel: true})
  //isIntroFinished && setVertical.start({cancel:true})


  //isIntroFinished && verticalStart()
  //isIntroFinished && horizontalStart()


/* ***************************************** */
  //THE FUCKING EYE STARTS BELOW


  const regularTop = `M61.6,30C50.2,4.6,8.3,9.7,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z`
  const regularBottom = `M61.6,30C50.2,55.3,8.3,50.3,0.2,30c0.5,16.6,14,29.8,30.7,29.8S61.2,46.5,61.6,30z`
  const squintTop = `M61.6,30C53,19.1,9.3,18.9,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z`
  const squintBottom = `M61.6,30C51,43.4,10.3,41.7,0.2,30c0.5,16.6,14,29.8,30.7,29.8S61.2,46.5,61.6,30z`
  
  const slightlyTop = `M61.6,30C50.1,8.8,7,13.3,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z`
  const slightlyBottom = `M61.6,30C50.5,50.9,6.3,45.6,0.2,30c0.5,16.6,14,29.8,30.7,29.8S61.2,46.5,61.6,30z`
  
  const closedTop = `M61.6,30C61,29.9,0.4,30,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z`
  const closedBottom = `M61.6,30C60.4,30.1,1,30,0.2,30c0.5,16.6,14,29.8,30.7,29.8S61.2,46.5,61.6,30z`

  const tempInitialDelay = 4000

  /*
  const irisController = useSpringRef()
  const bottomEyeController = useSpringRef()
  const topEyeController = useSpringRef()
  const middleController = useSpringRef()
  */
  


/*
  const [irisAnimated, setIris] = useSpring(() => ({
    from: { transform: 'scale(1) translate(-50%,-50%)',
    transformOrigin: 'top left'},
    to: [
      {transform: 'scale(0.85) translate(-50%,-50%)',
        transformOrigin: 'top left',
        config: { precision: .001, duration: 600, easing: easeOutSine },
        //delay: tempInitialDelay + 700 - 183,
      },
      {
        transform: 'scale(0.67) translate(-50%,-50%)',
        transformOrigin: 'top left',
        config: { precision: .001, duration: 370, easing: easeOutCubic },
        delay: 1370
      },
      {
        transform: 'scale(0.85) translate(-50%,-50%)',
        transformOrigin: 'top left',
        config: { precision: .001, duration: 510, easing: easeOutSine  },
        delay: 1910,
      },
      {
        delay: 7273,

      }
    ],
    ref: irisController,
    reset: true,
    delay: tempInitialDelay + 700 - 183,
    loop: true
  }))
  */

  const [irisAnimated, setIris] = useSpring(() => ({
    from: { x:18.6 },
    
    //ref: irisController,
    //reset: true,
    //delay: tempInitialDelay + 700 - 183,
    
    //loop: true
  }))


  const [middleEyeAnimated, setMiddleEye] = useSpring(() => ({
    //translateY: '-50%',
    from: { translateX: '-50%', translateY: '-50%' },
    
    //reset: true,
    //ref: middleController,
    //loop: true,
  }))
  const [topEyeAnimated, setTopEye] = useSpring(() => ({
    from: { x: slightlyTop},
    
    //ref: topEyeController,
    //reset: true,
    //loop: true,
  }))
  const [bottomEyeAnimated, setBottomEye] = useSpring(() => ({
    from: { x: slightlyBottom},
    
    //ref: bottomEyeController,
    //reset: true,
    //loop: true,
  }))
  

  //isIntroFinished && setBottomEye.start()
  //isIntroFinished && setTopEye.start()
  //isIntroFinished && setIris.start()
  //isIntroFinished && setMiddleEye.start()






/* ***************************************** */
  
  
  //const vertController = useSpringRef()
  //const horController = useSpringRef()


  /*
  const [verticalAnimation, setVertAnimation] = useSpring(()=>({
    from: { translateY: '-270%' },
    to: [
      //{translateY: '-270%', delay: 2800, config: { duration: 0 }},
      { translateY: '-65%', config:{ duration: 2800, easing: easeOutCirc } },
      { translateY: '-77%', config:{ duration: 2500, easing: easeOutSine }, delay:50 },
      { translateY: '-59%', config:{ duration: 3650, easing: easeInOutSine } },
      { translateY: '1725%', config:{ duration: 3400, easing: easeInCirc } },
      { translateY: '1725%', config:{ duration: 0 }, delay:1350 }
    ],
    delay: 2800,
    
    reset: true,
    ref: vertController,
    //loop:true
  }))
  const [horAnimation, setHorAnimation] = useSpring(()=>({
    from: { translateX: '1200%' },
    to: [
      { translateX: '1200%',delay: 500, config: { duration: 0 }},
      { translateX: '-43%', config: { duration: 2900, easing: easeOutCirc, precision: .001 } },
      { translateX: '-600%', config:{ duration: 2500 , easing: easeInCubic }, delay: 6150 },
      { translateX: '-8000%', config:{ duration: 2300 } },
    ],
    delay: 2200,
    reset: true,
    ref: horController,
    //loop: true,
    //onRest: () => setRestart(!restartAnimation)
  }))
  */
  const [verticalAnimation, setVertAnimation] = useSpring(()=>({
    from: { translateY: '-270%' },
    /*
    to: [
      //{translateY: '-270%', delay: 2800, config: { duration: 0 }},
      { translateY: '-65%', config:{ duration: 2800, easing: easeOutCirc } },
      { translateY: '-77%', config:{ duration: 2500, easing: easeOutSine }, delay:50 },
      { translateY: '-59%', config:{ duration: 3650, easing: easeInOutSine } },
      { translateY: '1725%', config:{ duration: 3400, easing: easeInCirc } },
      { translateY: '1725%', config:{ duration: 0 }, delay:1350 }
    ],
    delay: 2800,
    
    reset: true,
    ref: vertController,
    */
    //loop:true
  }))
  const [horAnimation, setHorAnimation] = useSpring(()=>({
    from: { translateX: '1200%' },
    /*
    to: [
      { translateX: '1200%',delay: 500, config: { duration: 0 }},
      { translateX: '-43%', config: { duration: 2900, easing: easeOutCirc, precision: .001 } },
      { translateX: '-600%', config:{ duration: 2500 , easing: easeInCubic }, delay: 6150 },
      { translateX: '-8000%', config:{ duration: 2300 } },
    ],
    delay: 2200,
    reset: true,
    ref: horController,
    */
    //loop: true,
    //onRest: () => setRestart(!restartAnimation)
  }))




  useLayoutEffect(() => {
    setHorAnimation.start({
      from: { translateX: '1200%' },
      to: [
        { translateX: '1199%',delay: 0, config: { duration: 2700 }},
        { translateX: '-43%', config: { duration: 2900, easing: easeOutCirc, precision: .001 } },
        { translateX: '-600%', config:{ duration: 2500 , easing: easeInCubic }, delay: 6150 },
        //{ translateX: '-8000%', config:{ duration: 2300 } },
      ],
      //delay: 2200,
      //reset: true,
      pause: isIntroFinished ? false : true
    })

    setVertAnimation.start({
      from: { translateY: '-270%' },
      to: [
        {translateY: '-269%', delay: 0, config: { duration: 2800 }},
        { translateY: '-65%', config:{ duration: 2800, easing: easeOutCirc } },
        { translateY: '-77%', config:{ duration: 2500, easing: easeOutSine }, delay:50 },
        { translateY: '-59%', config:{ duration: 3650, easing: easeInOutSine } },
        { translateY: '1725%', config:{ duration: 3400, easing: easeInCirc } },
        //{ translateY: '1725%', config:{ duration: 0 }, delay:1350 }
      ],
      pause: isIntroFinished ? false : true
      //delay: 2400,
      //reset: true,
    })

    setMiddleEye.start({
      from: { translateX: '-50%', translateY: '-50%' },
      to: [
        {
          translateX: '-70%',
          config: { duration: 200, precision: .001 },
          delay: tempInitialDelay + 2600 - 183,
        },
        {
          translateX: '-30%',
          config: { duration: 210,
            precision: .001, easing: easeOutSine },
          delay: 510,
        },
        {
          translateX: '-50%',
          config: { duration: 500, precision: .001, easing: easeOutSine },
          delay: 1400,
        },
  
      ],
      pause: isIntroFinished ? false : true,
    })

    setTopEye.start({
      from: { x: slightlyTop},
      to: [
        { 
          x: regularTop,
          config: { duration: 1500, precision: .001, easing: easeOutCubic },
          delay: tempInitialDelay + 500,
        },
        {
          x: closedTop,
          config: { duration: 180, precision: .001 },
          delay: 250,
        },
        {
          x: regularTop,
          config: { duration: 180, precision: .001 },
        },
        {
          x: closedTop,
          config: { duration: 150, precision: .001 },
          delay: 450,
        },
        {
          x: regularTop,
          config: { duration: 150, precision: .001 },
        },
        {
          x: closedTop,
          config: { duration: 150, precision: .001 },
          delay: 1250,
        },
        {
          x: regularTop,
          config: { duration: 150, precision: .001 },
        },
        {
          x: squintTop,
          config: { duration: 500, precision: .001, easing: easeOutQuint },
          delay: 1740,
        },
      ],
      pause: isIntroFinished ? false : true,
    })

    setBottomEye.start({
      from: { x: slightlyBottom},
    to: [
      { 
        x: regularBottom,
        config: { duration: 1500, precision: .001, easing: easeOutCubic },
        delay: tempInitialDelay + 500,
      },
      {
        x: closedBottom,
        config: { duration: 180, precision: .001 },
        delay: 250,
      },
      {
        x: regularBottom,
        config: { duration: 180, precision: .001 },
      },
      {
        x: closedBottom,
        config: { duration: 150, precision: .001 },
        delay: 450,
      },
      {
        x: regularBottom,
        config: { duration: 150, precision: .001 },
      },
      {
        x: closedBottom,
        config: { duration: 150, precision: .001 },
        delay: 1250,
      },
      {
        x: regularBottom,
        config: { duration: 150, precision: .001 },
      },
      {
        x: squintBottom,
        config: { duration: 500, precision: .001, easing: easeOutQuint },
        delay: 1740,
      },
      ],
      pause: isIntroFinished ? false : true,
    })

    setIris.start({
      from: { x:18.6 },
      to: [
        {
          x: 18.5,
          config: { precision: .001, duration: 700, easing: easeOutSine },
          //delay: tempInitialDelay + 700 - 183,
        },
        {
          x: 15.6,
          config: { precision: .001, duration: 600, easing: easeOutSine },
          //delay: tempInitialDelay + 700 - 183,
        },
        {
          x: 12.3,
          config: { precision: .001, duration: 370, easing: easeOutCubic },
          delay: 1370
        },
        {
          
          x: 15.6,
          config: { precision: .001, duration: 510, easing: easeOutSine  },
          delay: 1910,
        },
        
      ],
      delay: tempInitialDelay - 183 + 50,
      pause: isIntroFinished ? false : true
    })

    const timer = setTimeout(() => {
      setRestart(!restartAnimation)
    }, 16700);
    return () => clearTimeout(timer);
  })




  //useChain([horController, vertController,bottomEyeController,topEyeController,middleController,irisController], [0,0])
  
  
  
  /*
  isIntroFinished && vertController.start({cancel: true})
  isIntroFinished && horController.start({cancel: true})
  isIntroFinished && vertController.start()
  isIntroFinished && horController.start()
  */

  //const testFunction = () => setRestart(!restartAnimation)
  //FINISH BELOW

  //Needs to reset current state so it isnt frozen on screen
  //Returns to app:
  const onFocus = (isIntroFinished) => 
    isIntroFinished && 
    setRestart(!restartAnimation)

  //Leaves App:
  //const onBlur = (isIntroFinished) => isIntroFinished && setRestart(!restartAnimation)
  

  //const onFocus = () => setRestart(!restartAnimation)
  //Leaves App:
  //const onBlur = () => setRestart(!restartAnimation)
  //const onAnimationFinished = () => console.log('animation has finished!!!!') 
  
 
  useLayoutEffect(() => {
    //console.log('start me up')
    //const horizontalElement = document.getElementById('horizontal')
    //console.log('tester', horizontalElement.tagName)
    window.addEventListener('focus', onFocus);
    //window.addEventListener('blur', onBlur);



    //horizontalElement.addEventListener('animationstart', onAnimationFinished)
    // Specify how to clean up after this effect:
    return () => {
      //console.log('clean me up')
      window.removeEventListener('focus', onFocus);
      //window.removeEventListener('blur', onBlur);
    };
  }, []);


  const [ restartAnimation, setRestart ] = useState(false)
  //const testFunction = () => setRestart(!restartAnimation)
  
  
  return(
    <>
      {/*
      <animated.div className='largeWhaleHorizontal' style={ largeWhaleHorizontal } >
      <animated.div className='largeWhaleVertical' style={ largeWhaleVertical } >
    */}
        <animated.div className='largeWhaleHorizontal' id='horizontal' style={ horAnimation } >
        <animated.div className='largeWhaleVertical' style={ verticalAnimation } >
          <div className='innerWhaleTest'>
            <img className='whaleSRC' src={ fullLargeBody } />
            <div className='outerEyeContainer'>
              


            <div className='eyeWrap'>
        <div className='eyeWrinklesWrap'>
          <img src={ outerWrinkles } />
        </div>
        <svg version="1.1" id="whitePart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	      viewBox="0 0 61.8 60">
          <ellipse className="st0" cx="30.9" cy="30" rx="30.7" ry="29.9"/>
        </svg>


      <animated.div className='middleEyePart' style={ middleEyeAnimated }>
        <div className='middleEyeWrap'>


          <svg version="1.1" id="bluePart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 37.3 37.3"  >
            <circle className="st0" cx="18.6" cy="18.6" r="18.6"/>
          </svg>



          <svg version="1.1" id="blackPart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" 
	          viewBox="0 0 37.3 37.3" >
              <animated.circle className="st0" id='circleSVG' cx="18.6" cy="18.6" r={ irisAnimated.x } />
          </svg>



          <svg version="1.1" id="reflectionPart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	          viewBox="0 0 37.3 37.3">
            <circle className="st0" cx="12.7" cy="8.9" r="4.6"/>
          </svg>
        </div>
      </animated.div>{/*End of middle eye part */}




      {/*
      <svg version="1.1" id="whaleEyeLid" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	      viewBox="0 0 61.8 60">
      
      <animated.path className="st0" d="M61.6,30C50.2,4.6,8.3,9.7,0.2,30c0.5-16.6,14-29.8,30.7-29.8C47.6,0.1,61.2,13.4,61.6,30z"/>
      <path className="st0" d="M61.6,30C50.2,55.3,8.3,50.3,0.2,30c0.5,16.6,14,29.8,30.7,29.8C47.6,59.8,61.2,46.5,61.6,30z"/>
      </svg>
      */}

      
      
      <svg version="1.1" id="whaleEyeLid" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	      viewBox="0 0 61.8 60">
      <animated.path className="st0" d={ topEyeAnimated.x } />

      {/*
      <animated.path className="st0" d={ eyeLidInter.to({
        range: [0,.16,.3,.32,.34,.44,.46,.48,.67,.69,.71,.97,1],
        output: [ slightlyBottom,regularBottom,regularBottom,closedBottom,regularBottom,regularBottom,closedBottom,regularBottom, regularBottom, closedBottom,regularBottom,regularBottom, squintBottom ]
      }).to(y=>`${y}`) } /> 
      */}
      <animated.path className="st0" d={ bottomEyeAnimated.x } /> 
      
      </svg>
    </div>
            </div>
          </div>
        </animated.div>
      </animated.div>
    </>
  )
}

export default memo(LargeWhale);