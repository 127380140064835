import './hamburger.scss';
import hamburgerIcon from './Hamburger Icon.svg'
import hamburgerCrossIcon from './Hamburger Cross Icon.svg';
import instagramLogo from './instagramLogo.png';
import twitterLogo from './twitterLogo.png';
import cap from '../Background/Cap.svg';
import step1 from '../Background/Step1.svg';
import step2 from '../Background/Step2.svg';
import step3 from '../Background/Step3.svg';
import phoneIcon from '../HeroOverlay/phoneIcon.png';
import emailIcon from '../HeroOverlay/emailIcon.png';

import { useState, memo, useRef, useLayoutEffect } from "react";
import { useSpring, animated } from 'react-spring';
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

const Hamburger = ({isIntroFinished, updateBurger, resetBurgerVar}) => {
  
  //const easeOutSine = x => (Math.sin((x * Math.PI) / 2))
  const easeOutCubic = x => (1 - Math.pow(1 - x, 3))
  const easeOutQuint = (x) => (1 - Math.pow(1 - x, 5))
  
  const [hamburgerStatus, setHamburgerStatus] = useState(true)
  

  const [activateCross, setCross] = useSpring(() => ({
    translateX: '-300%', translateY:'-50%'
  }))
  const [activateHamburgerIcon, setHamburgerIcon] = useSpring(() => ({
    translateX: '-50%', translateY:'-50%'
  }))

  const [hamburgerMenuAnimated, setMenu] = useSpring(() => ({
    translateX: '-100%'
  }))
  const [rightPanel, setRight] = useSpring(() => ({
    translateX:'10px'
  }))
  const [topPanel, setTop] = useSpring(() => ({
    translateY:'-10px'
  }))
  const [bottomPanel, setBottom] = useSpring(() => ({
    translateY:'10px'
  }))
  const [bottomInnerPanel, setBottomInner] = useSpring(() => ({
    opacity: 1
  }))
  const [hamburgerBlue, setBlue] = useSpring(() => ({
    opacity: 0, //scale: .95
  }))
  
  const hamburgerButtonAppear = useSpring({
    from: { transform: 'translateY(-80px)' },
    to: { transform: 'translateY(0px)' },
    pause: !isIntroFinished ? true : false,
    delay: 300,
    config: { easing: easeOutQuint, duration: 1000 }
  })
  
  //Activate menu
  isIntroFinished && (!hamburgerStatus || updateBurger) && setMenu.start({
    
    to: { translateX:'0%' },
    config: { duration: 400, easing: easeOutCubic }
  })
  isIntroFinished && (!hamburgerStatus || updateBurger) && setRight.start({
    
    to: { translateX:'0px' }
  })
  isIntroFinished && (!hamburgerStatus || updateBurger) && setBottom.start({
    to: { translateY:'0px' }
  })
  isIntroFinished && (!hamburgerStatus || updateBurger) && setTop.start({
    
    to: { translateY:'0px' }
  })
  isIntroFinished && (!hamburgerStatus || updateBurger) && setBlue.start({
    to: {opacity: 1}
  })
  isIntroFinished && (!hamburgerStatus || updateBurger) && setCross.start({
    to: {translateX: '-50%'},
    config: { duration: 360, easing: easeOutQuint }
  })
  isIntroFinished && (!hamburgerStatus || updateBurger) && setHamburgerIcon.start({
    to: {translateX: '300%'},
    config: { duration: 360, easing: easeOutQuint }
  })
  isIntroFinished && (!hamburgerStatus || updateBurger) && setBottomInner.start({
    to: {opacity: 1},
    config: { duration: 0 }
  })



  //Deactivate
  isIntroFinished && (hamburgerStatus && !updateBurger) && setMenu.start({
    to: { translateX:'-100%' },
  })
  isIntroFinished && (hamburgerStatus && !updateBurger) && setRight.start({
    to: { translateX:'10px' },
  })
  isIntroFinished && (hamburgerStatus && !updateBurger) && setBottom.start({
    to: { translateY:'10px' }
    /*
    to: [
      { translateY:'10px', opacity: 1 },
      { opacity:0, config: {duration: 0} }
    ]
    */
  })
  isIntroFinished && (hamburgerStatus && !updateBurger) && setTop.start({
    to: { translateY:'-10px' }
  })
  isIntroFinished && (hamburgerStatus && !updateBurger) && setBlue.start({
    to: {opacity: 0}
  })
  isIntroFinished && (hamburgerStatus && !updateBurger) && setCross.start({
    to: {translateX: '-300%'},
    config: { duration: 360, easing: easeOutQuint }
  })
  isIntroFinished && (hamburgerStatus && !updateBurger) && setHamburgerIcon.start({
    to: {translateX: '-50%'},
    config: { duration: 360, easing: easeOutQuint }
  })
  isIntroFinished && (hamburgerStatus && !updateBurger) && setBottomInner.start({
    to: {opacity: 0},
    config: { duration: 0 },
    delay: 325
  })


  const bodyTarget = useRef(null)

  useLayoutEffect(() => {
    bodyTarget.current = document.getElementById('bodyLock')
    const contentWrapper = document.getElementById('contentWrap')
    const height = ''+ window.innerHeight + 'px'
    contentWrapper.style.height = height

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])
  useLayoutEffect(() => {

    //Set content wrap to current height
    const contentWrapper = document.getElementById('contentWrap')
    const height = ''+ window.innerHeight + 'px'
    contentWrapper.style.height = height
    

    //blue panel height will always be equal or larger than window height
    //Set bottom panel 'bottom' to offset the current height
    const bluePanelHeight = document.getElementById('bluePanel').clientHeight
    const bottomPanel = document.getElementById('bottomPanel')
    const bottomStyle = '' + Math.abs(bluePanelHeight - window.innerHeight) + 'px'
    bottomPanel.style.bottom = bottomStyle

    if(isIntroFinished){
      //Either 1st or 2nd trigger is activated
      if(!hamburgerStatus || updateBurger){
        disableBodyScroll(bodyTarget.current)
        //Change hamburger menu height to current height upon clicking
        
      } 
      else clearAllBodyScrollLocks()
    }


  }, [hamburgerStatus,updateBurger])

  const handleButtonClick = () => {
    //If 2nd switch is on, turn it off. if 1st switch is on turn off
    if(!hamburgerStatus && !updateBurger){
      //regular occurance; just switch hamburgerStatus off
      setHamburgerStatus(true)
    }

    else if(hamburgerStatus && !updateBurger){
      //regular occurance; just switch hamburgerStatus on
      setHamburgerStatus(false)
    }
    else if(hamburgerStatus && updateBurger){
      //2nd trigger was activated; turn off using parent function
      resetBurgerVar()
    }
    else if(!hamburgerStatus && updateBurger){
      //shouldnt happen; both triggers cant be on at once; menu was up and updateBurger became true? not possible
      //setHamburgerStatus(false)
    }
  }

  //Remove scroll bar temporarily removed here b/c it forces scroll to top
  //in future, we can restore scroll position
  //after reverting versions of body scroll and removing html, body stuff, removescroll not needed

  return(
    <>
    {/* !hamburgerStatus &&
      <RemoveScrollBar />
    */}
    <div className='hamburgerMenu' style={{
      pointerEvents: (hamburgerStatus && !updateBurger) ? 'none' : 'auto'
    }} >
      <div className='hamburgerWrap' >
        <animated.div className='hamburgerBluePanel' id='bluePanel' onClick={ handleButtonClick } style={ hamburgerBlue } ></animated.div>
        <animated.div className='hamburgerTopPanel' style={ topPanel }></animated.div>
        <animated.div className='hamburgerBottomPanel' id='bottomPanel' style={ bottomPanel }>
          <animated.div className='hamburgerBottomPanelInner' style={ bottomInnerPanel }></animated.div>
        </animated.div>
        <animated.div className='hamburgerRightPanel' style={ rightPanel }></animated.div>
        <animated.div className='hamburgerWhitePanel' style={ hamburgerMenuAnimated } >
          <div id='contentWrap'>
            <div className='innerContent'>
              <div className='innerPadding'>
              <div className='topSection'>
                <div className='logoWrapper'>
                  <img src={ cap } />
                  <img src={ step1 } />
                  <img src={ step2 } />
                  <img src={ step3 } />
                </div>
                <div className='topText first'>Stay ahead of the curve!</div>
                <div className='topText'>Let's build the products and services of tomorrow.</div>
              </div>
              <div className='bottomText'>
              <h1>Connect With Us</h1>
              <a className='bottomLinkWrap' href="tel:7066317004">
                <div className='bottomTextPhone'>
                  <img src={ phoneIcon } />
                  <div>(706) 631-7004</div>
                </div>
              </a>
              <a className='bottomEmailWrap' href="mailto:contact@smartstepdigital.com">
                <div className='bottomText1stLine'>
                  <img className='icon' src={ emailIcon } />
                  <div>contact@smartstepdigital.com</div>
                </div>
              </a>
              <h1 className='socialMedia'>Social Media</h1>
              <div className='comingSoon'>Coming Soon</div>
              <div className='socialMediaBar'>
                <img src={ instagramLogo  } />
                <img className='twitter' src={ twitterLogo } />
              </div>
            </div>



              </div>
            </div>
            



            {/*
            <div className='topSection'>
              <div className='logoWrapper'>
                <img src='/pictures/Cap.svg' />
                <img src='/pictures/Step1.svg' />
                <img src='/pictures/Step2.svg' />
                <img src='/pictures/Step3.svg' />
              </div>
            <div className='topText'>Stay ahead of the curve!</div>
            <div className='topText'>Let's build the products and services of tomorrow.</div>
            </div>
            <div className='bottomText'>
              
              <h1>Connect With Us</h1>
              <a className='bottomLinkWrap' href="tel:7203355812">
                <div className='bottomTextPhone'>
                  <img src='./pictures/phoneIcon.png' />
                  <div>(720) 335-5812</div>
                </div>
              </a>
              <a className='bottomEmailWrap' href="mailto:contact@smartstepdigital.com">
                <div className='bottomText1stLine'>
                  <img className='icon' src='./pictures/emailIcon.png' />
                  <div>contact@smartstepdigital.com</div>
                </div>
              </a>
              <h1 className='socialMedia'>Social Media</h1>
              <div className='comingSoon'>Coming Soon</div>
              <div className='socialMediaBar'>
                <img src='./pictures/instagramLogo.png' />
                <img className='twitter' src='./pictures/twitterLogo.png' />
              </div>
            </div>
            */}

          </div>
        </animated.div>
      </div>
    </div>
    <animated.div className='hamburger' onClick={ handleButtonClick } style={ hamburgerButtonAppear }>
      <div className='hamburgerIconWrapper'>
        <animated.div className='hamburgerIcon' style={ activateHamburgerIcon }>
          <img src={ hamburgerIcon } />
        </animated.div>
        <animated.div className='closeHamburgerIcon' style={ activateCross }>
          <img src={ hamburgerCrossIcon } />
        </animated.div>
        
      </div>
        
      
    </animated.div>
    </>
  )
}

export default memo(Hamburger);