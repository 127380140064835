import { memo, useLayoutEffect, useRef } from 'react'

const DesktopScrollHandler = ({ handleDesktopBackground }) => {

  const sectionDosTop = useRef(null)
  const isAnimationActive = useRef(false)

  const handleScrolling = () => {
    const position = window.pageYOffset;

    //console.log('scroll position:',position)
    //console.log('window height:',window.innerHeight)
    //console.log('top of section:',sectionDosTop.current.offsetTop)
    //console.log('top of section:',sectionDosMain.current.offsetTop)
    //console.log('window+position:',window.innerHeight + position)
    const whitePanelOffset = window.innerHeight * 0.2
    //console.log('percentage:', whitePanelOffset)
    if((window.innerHeight + position) > (sectionDosTop.current.offsetTop+whitePanelOffset)){
    
    if(!isAnimationActive.current){
      //Section Dos is in view and needs to be activated
      isAnimationActive.current = true
      handleDesktopBackground('activated')
      //console.log('activate')
    }
    }else{
      //Deactivate if not already; out of view
      if(isAnimationActive.current){
        isAnimationActive.current = false
        handleDesktopBackground('DEactivated')
        //console.log('deactivate')
      }
    }

  }
  
  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScrolling, { passive: true });


    sectionDosTop.current = document.getElementById('sectionDosTrigger')
    //sectionDosMain.current = document.getElementById('sectionDosMain')


    return () => {
      window.removeEventListener('scroll', handleScrolling)
    }
  }, [])
  
  return null
}

export default memo(DesktopScrollHandler)