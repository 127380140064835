import './section2.scss';
import ArtSquare from '../ArtSquare/ArtSquare';

import { memo } from 'react';

const Section2 = ({activateArt, openTheBurger}) => {

  /*
  const easeOutCubic = x => (1 - Math.pow(1 - x, 3))

  const [fallingAnimation, setFalling] = useSpring(()=>({
    translateY: '-10%', translatex: '-50%'
  }))
  useEffect(() => {
    setFalling.start({
      to: { translateY: '48%' },
      config: { easing: easeOutCubic, duration: 9000 },
      loop: true,
      delay: 5000
    })
  }, [])
  */


  return(
    <div className='sectionTwoMain' id='sectionTwoMain'>
      <div className='picture'>
        <ArtSquare activateSquares={ activateArt } />
      </div>
      <div className='missionSection'>
        <h2>Mission and Vision</h2>
          <p>
            We are Smart Step Digital, a digital marketing company focused on providing modern solutions to businesses around the globe.
            Our hard work and attention to detail allows us to turn business ideas into digital masterpieces.
            With every design, we provide a unique and memorable user experience that results in satisfied customers.
            We are dedicated to helping businesses achieve success in every step of the digital process, from brainstorming to launching a polished product.
          </p>
      </div>
      
      <ul className='services'>
        <h2>Skills</h2>
        <li>Website Design/Maintenance</li>
        <li>Front-End Development</li>
        <li>Logo Design</li>
        <li>UI/UX Design/Wireframing</li>
        <li>Social Media Marketing</li>
      </ul>




      <div className='ctaBottomButton' onClick={ openTheBurger }>Contact Us Now</div>
      
    </div>
  )
}

export default memo(Section2)