import './heroOverlay.scss';
import emailIcon from './emailIcon.png';
import phoneIcon from './phoneIcon.png';
import stairsCloseBlue from './Stairs Close Blue.svg';
import stairsCloseCross from './Stairs Close Cross.svg';
import stairsMainPart from './Stairs Main Part.svg';
import instagramLogo from '../Hamburger/instagramLogo.png';
import twitterLogo from '../Hamburger/twitterLogo.png';
import logo from '../Background/newerLogo.svg';
import cap from '../Background/Cap.svg';
import step1 from '../Background/Step1.svg';
import step2 from '../Background/Step2.svg';
import step3 from '../Background/Step3.svg';

import { memo, useRef, useLayoutEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const HeroOverlay = ({isIntroFinished, ctaTrigger}) => {

  const easeOutCubic = x => (1 - Math.pow(1 - x, 3))
  const easeInCubic = x => (x * x * x)
  const easeInOutQuart = x => (x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2)
  const easeOutSine = x => (Math.sin((x * Math.PI) / 2))

  const hamburgerClicked = useRef(false)
  const hamburgerHovering = useRef(false)

  const panelContent = useRef(null)
  
  

  const borderDuration = 600

  const [topAnimation, setTop] = useSpring(() =>({
    from: { translateY: '-4vw' },
  }))
  const [bottomAnimation, setBottom] = useSpring(() => ({
    from: { translateY: '4vw' }
    
  }))
  const [leftAnimation, setLeft] = useSpring(() => ({
    from: { translateX: '-4vw' }
  }))
  const [rightAnimation, setRight] = useSpring(() => ({
    from: { translateX: '4vw' },
  }))

  const [logoAnimations, setLogoAnimations] = useSpring(() => ({
    from: { translateX: '0vw', translateY: '0vw' },
  }))

  const [ closeButton, setClose ] = useSpring(() => ({
    from: { opacity: 0, translateX: '-0.2vw' },
  }))


  useLayoutEffect(() => {
    isIntroFinished && setTop.start({
      from: { translateY: '-4vw' },
      to: { translateY: '0vw' },
      config: { duration: borderDuration, easing: easeOutCubic },
      delay: 100
    })
    isIntroFinished && setBottom.start({
      from: { translateY: '4vw' },
      to: { translateY: '0vw' },
      config: { duration: borderDuration, easing: easeOutCubic },
      delay: 100
    })
    isIntroFinished && setLeft.start({
      from: { translateX: '-4vw' },
      to: { translateX: '0vw' },
      config: { duration: borderDuration, easing: easeOutCubic },
      delay: 100
    })
    isIntroFinished && setRight.start({
      from: { translateX: '4vw' },
      to: { translateX: '0vw' },
      config: { duration: borderDuration, easing: easeOutCubic },
      delay: 100
    })
  }, [isIntroFinished])
  
  useLayoutEffect(() => {
    if(ctaTrigger !== null){
      //hamburgerClicked.current = true
      hamburgerWasClicked()
    }
  }, [ctaTrigger])

  useLayoutEffect(() => {
    panelContent.current = document.getElementById('panelContent')
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  /*
  useEffect(() => {
    setTop.start({
      from: { translateY: '-4vw' },
      to: { translateY: '0vw' },
      config: { duration: borderDuration, easing: easeOutCubic },
      delay: 500
    })
    setBottom.start({
      from: { translateY: '4vw' },
      to: { translateY: '0vw' },
      config: { duration: borderDuration, easing: easeOutCubic },
      delay: 500
    })
    setLeft.start({
      from: { translateX: '-4vw' },
      to: { translateX: '0vw' },
      config: { duration: borderDuration, easing: easeOutCubic },
      delay: 500
    })
    setRight.start({
      from: { translateX: '4vw' },
      to: { translateX: '0vw' },
      config: { duration: borderDuration, easing: easeOutCubic },
      delay: 500
    })
  }, [])
  */
  


  
  const [menuTextAnimation, setMenuText] = useSpring(() => ({
   //translateX: '0vw', opacity: 1
   opacity: 1
  }))
  const [stairAnimation, setStairs] = useSpring(() => ({
    transform: 'scaleX(1)', transformOrigin: '100% 50%', opacity: 1
  }))
  const [ colorChange, setColor] = useSpring(() => ({
    fill: '#0F0F0F'
  }))

  const [ hamburgerPanel, setHamburgerPanel ] = useSpring(() => ({
    opacity: 0
  }))

  /*
  //Enter Hover
  isHamburgerHovering && setMenuText.start({
    //from: { translateX: '-80px' },
    to: { translateX: '-0.2vw' }
  })
  isHamburgerHovering && setStairs.start({
    to: { transform: 'scaleX(1.2)', transformOrigin: '100% 50%' }
  })
  isHamburgerHovering && setColor.start({
    to: { fill: '#315589' }
  })
  isHamburgerHovering && setLeft.start({
    to: { translateX: '4vw' }
  })

  //Leave Hover
  !isHamburgerHovering && setMenuText.start({
    //from: { translateX: '-80px' },
    to: { translateX: '0vw' }
  })

  !isHamburgerHovering && setStairs.start({
    to: { transform: 'scaleX(1)', transformOrigin: '100% 50%' }
  })
  !isHamburgerHovering && setColor.start({
    to: { fill: '#0F0F0F' }
  })
  !isHamburgerHovering && setLeft.start({
    to: { translateX: '0vw' }
  })

  */


  

  //const hamburgerClicked


  const hamburgerEnterHover = () => {
    if(!hamburgerHovering.current) hamburgerHovering.current = true
  
    !hamburgerClicked.current && setStairs.start({
      to: { transform: 'scaleX(1.2)', transformOrigin: '100% 50%' }
    })
    !hamburgerClicked.current && setColor.start({
      to: { fill: '#315589' }
    })
    !hamburgerClicked.current && setLeft.start({
      to: { translateX: '4vw' },
      //config: { easing: easeOutSine }
    })
    !hamburgerClicked.current && setLogoAnimations.start({
      to: {translateX: '4vw', translateY: '0vw'},
      //config: { easing: easeOutSine }
    })
    
  }
  const hamburgerLeaveHover = () => {
    hamburgerHovering.current && !hamburgerClicked.current && setStairs.start({
      to: { transform: 'scaleX(1)', transformOrigin: '100% 50%' }
    })
    hamburgerHovering.current && !hamburgerClicked.current && setColor.start({
      to: { fill: '#0F0F0F' }
    })
    hamburgerHovering.current && !hamburgerClicked.current && setLeft.start({
      to: { translateX: '0vw' }
    })
    hamburgerHovering.current && !hamburgerClicked.current && setLogoAnimations.start({
      to: {translateX: '0vw', translateY: '0vw'},
      //config: { duration: borderDuration, easing: easeOutCubic },
    })
    
    if(hamburgerHovering.current) hamburgerHovering.current = false
    
  }


  const hamburgerWasClicked = () => {
    if(hamburgerClicked.current){
      //Deactivate
      clearAllBodyScrollLocks()
      setTop.start({
        //from: { translateY: '-4vw' },
        to: { translateY: '0vw' },
        config: { duration: borderDuration, easing: easeOutCubic },
      })
      setBottom.start({
        //from: { translateY: '4vw' },
        to: { translateY: '0vw' },
        config: { duration: borderDuration, easing: easeOutCubic },
        
      })
      !hamburgerHovering.current && setLeft.start({
        //from: { translateX: '-4vw' },
        to: { translateX: '0vw' },
        //config: { duration: borderDuration, easing: easeOutCubic },
        
      })
      setRight.start({
        //from: { translateX: '4vw' },
        to: { translateX: '0vw' },
        config: { duration: borderDuration, easing: easeOutCubic },
        
      })
      !hamburgerHovering.current && setLogoAnimations.start({
        to: {translateX: '0vw', translateY: '0vw'},
        config: { duration: borderDuration, easing: easeOutCubic },
      })
      setHamburgerPanel.start({
        to: { opacity: 0 },
        config: { duration: 450, easing: easeOutCubic },
      })
      setClose.start({
        to: {opacity: 0},
        //config: { duration: 300 }
      })
      !hamburgerHovering.current && setMenuText.start({
        to: {opacity: 1},
      })
      !hamburgerHovering.current && setStairs.start({
        to: { opacity: 1 }
      })



      hamburgerHovering.current && setMenuText.start({
        to: { opacity: 1 }
      })
      hamburgerHovering.current && setStairs.start({
        to: { transform: 'scaleX(1.2)', transformOrigin: '100% 50%', opacity: 1 }
      })
      hamburgerHovering.current && setColor.start({
        to: { fill: '#315589' }
      })
      hamburgerHovering.current && setLeft.start({
        to: { translateX: '4vw' }
      })
      hamburgerHovering.current && setLogoAnimations.start({
        to: {translateX: '4vw', translateY: '0vw'},
        //config: { duration: borderDuration, easing: easeOutCubic },
      })

      hamburgerClicked.current = false
    } else{
      //Activate
      disableBodyScroll(panelContent.current)
      setClose.start({
        to: {opacity: 1},
        config: { duration: 0 }
      })
      setMenuText.start({
        to: { opacity: 0 },
        config: { duration: 0 }
      })
      setStairs.start({
        to: { opacity: 0 },
        config: { duration: 0 }
      })
      
      setTop.start({
        //from: { translateY: '-4vw' },
        to: { translateY: '4vw' },
        config: { duration: borderDuration, easing: easeOutCubic },
        
      })
      setBottom.start({
        //from: { translateY: '4vw' },
        to: { translateY: '-4vw' },
        config: { duration: borderDuration, easing: easeOutCubic },
        
      })
      setLeft.start({
        //from: { translateX: '-4vw' },
        to: { translateX: '88vw' },
        config: { duration: 450, easing: easeInOutQuart },
        
      })
      setHamburgerPanel.start({
        to: { opacity: 1 },
        config: { duration: 500, easing: easeOutCubic },
        delay: 150
      })
      setRight.start({
        //from: { translateX: '4vw' },
        to: { translateX: '-4vw' },
        config: { duration: borderDuration, easing: easeOutCubic },
        
      })
      setLogoAnimations.start({
        to: {translateX: '4vw', translateY: '0vw'},
        config: { duration: borderDuration, easing: easeOutCubic },
      })
      hamburgerClicked.current = true
    }
  }

  const hamburgerAppear = useSpring({
    from: { translateX:'-200%', translateY: '-50%' },
    to: { translateX: '35%' },
    config: { duration: 800, easing: easeOutCubic },
    pause: isIntroFinished ? false : true
  })

  return(
    <>
      <animated.div className='rightBox' style={ rightAnimation }>
      
      </animated.div>
      <animated.div className='leftBox' style={ leftAnimation }>
        <div className='panelWrap'>
          <div className='panelContent' id='panelContent'>
            <div className='topSection'>
              <div className='topLogoWrap'>
                <img src={ cap } />
                <img src={ step1 } />
                <img src={ step2 } />
                <img src={ step3 } />
              </div>
              <div className='topSectionText1'>Stay ahead of the curve!</div>
              <div className='topSectionText2'>Let's build the products and services of tomorrow.</div>
            </div>
            <div className='bottomSection'>
              <div className='bottomRightSection'>                
                <h1>Connect With Us</h1>                
                <a className='bottomLinkPhone' href="tel:7066317004">
                  <div className='bottomTextPhone'>
                    <img src={ phoneIcon } />
                    <div>(706) 631-7004</div>
                  </div>
                </a>
                <a className='bottomEmailWrap' href="mailto:contact@smartstepdigital.com">
                  <div className='bottomText1stLine'>
                    <img src={ emailIcon } />
                    <div>contact@smartstepdigital.com</div>
                  </div>
                </a>                                
              </div>
              <div></div>
              <div className='bottomLeftSection'>
                <h1>Social Media</h1>
                <h2>(Coming Soon)</h2>
                <img src={ instagramLogo } />
                <img className='twitter' src={ twitterLogo } />
                
              </div>
            </div>
          </div>
          <animated.div className='bluePanel' onClick={ hamburgerWasClicked } style={ hamburgerPanel }></animated.div>
        </div>
      </animated.div>
      <animated.div className='bottomBox' style={ bottomAnimation }></animated.div>

      <animated.div className='topBox' style={ topAnimation }>
        <animated.div className='desktopLogoWrap' style={ logoAnimations }>
          <div className='logoShell'>
            <img src={ logo } />
          </div>
        </animated.div>
      </animated.div>
      
      <animated.div className='hamburgerDesktop' onClick={ hamburgerWasClicked } onMouseEnter={ hamburgerEnterHover } onMouseLeave={ hamburgerLeaveHover } style={ hamburgerAppear } >
        <animated.img src={ stairsMainPart } style={ stairAnimation } />
        <animated.div className='hamburgerTextWrap' style={ menuTextAnimation }>
          <div>MENU</div>
        </animated.div>

        {/*
        <animated.svg version="1.1" id="hamburgerText" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          viewBox="0 0 91.6 91.6" style={ menuTextAnimation }>
          <g>
            <animated.path className="st0" d="M90.4,65.6l-13.6,3v0.1c1-0.1,2-0.2,2.9-0.2h10.7v2.8h-16v-4.6L87,64v-0.1l-12.6-2.7v-4.6h16v2.8H79.6
              c-0.9,0-1.8-0.1-2.8-0.2v0.1l13.6,3C90.4,62.3,90.4,65.6,90.4,65.6z" style={ colorChange }/>
            <animated.path className="st0" d="M90.4,46.5v7.2h-16v-7.2H77v4.2h3.8v-3.9h2.6v3.9h4.4v-4.2H90.4z" style={ colorChange }/>
            <animated.path className="st0" d="M90.4,33v3.6l-11.6,4.9v0.1c0.9-0.2,1.8-0.3,2.8-0.3h8.7v2.8h-16v-3.6l11.4-4.9v-0.1c-0.8,0.1-1.7,0.2-2.7,0.2
              h-8.7V33H90.4z" style={ colorChange }/>
            <animated.path className="st0" d="M74.5,20.4h10.3c1.9,0,3.4,0.4,4.4,1.3s1.5,2.1,1.5,3.7s-0.5,2.8-1.5,3.6s-2.5,1.2-4.4,1.2H74.5v-2.9H85
              c2,0,3-0.7,3-2c0-0.6-0.3-1.1-0.8-1.5c-0.5-0.3-1.3-0.5-2.2-0.5H74.5V20.4z" style={{
                //...colorChange,
                opacity: 0
              }} />
          </g>
          <rect className="st1" width="91.6" height="91.6"/>
        </animated.svg>
          */}
        

        <animated.img src={ stairsCloseCross } style={ closeButton } />
        <animated.img src={ stairsCloseBlue } style={ closeButton } />
      </animated.div>
    </>
  )
}


export default memo(HeroOverlay)