import './incompatible.scss';
import Cap from './Cap.svg';
import Step1 from './Step1.svg';
import Step2 from './Step2.svg';
import Step3 from './Step3.svg';

import { useMediaQuery } from 'react-responsive';

const Incompatible = () => {
  const aspectRatio = useMediaQuery({ query: '(min-aspect-ratio: 1/1)' });
  const isMobile = useMediaQuery({ query: '(max-width: 519px)' });
  if(isMobile && aspectRatio){
    return(
      <div className='incompatibleScreen'>
        <div className='incompatibleWrap'>
          <div className='incompatibleLogo'>
            <img src={ Cap } />
            <img src={ Step1 } />
            <img src={ Step2 } />
            <img src={ Step3 } />
          </div>
          <div className='incompatibleText1'>Oops, please rotate your device!</div>
          <div className='incompatibleText2'>It appears you are on a small device in landscape mode.</div>
          <div className='incompatibleText2'>This aspect ratio is currently not supported.</div>
        </div>
      </div>
    );
  } else return null;
};

export default Incompatible;