import './background.scss';
import cap from './Cap.svg';
import step1 from './Step1.svg';
import step2 from './Step2.svg';
import step3 from './Step3.svg';
import logo from './newerLogo.svg';

import { memo, useLayoutEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const Background = ({ isBackgroundOpen,introAnimationFinished,isIntroFinished, openHamburger, introCleanup }) => {
  //console.log('background rendered')

  
  const easeOutQuint = (x) => (1 - Math.pow(1 - x, 5))
  const easingTime = (t) => {
    return t < 0.5 ? 8 * t * t * t * t : 1 - Math.pow(-2 * t + 2, 4) / 2;
  }

  const bodyTarget = useRef(null)

  
  useLayoutEffect(() => {
    bodyTarget.current = document.getElementById('bodyLock')
    disableBodyScroll(bodyTarget.current)
    return () => {
      clearAllBodyScrollLocks()
    }
  },[])
  
  


  const {stairLogoTime} = useSpring({
    from: { stairLogoTime:0 },
    to: { stairLogoTime: 1 },
    config: { duration: 1200, precision: .001, easing: easeOutQuint },
    //loop: true,
    delay: 200,
    //onRest: () => introAnimationFinished()
  })

  const logoDisappear = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    pause: !isIntroFinished ? true : false

  })

  const logoAppear = useSpring({
    from: { transform: 'translateY(-80px)' },
    to: { transform: 'translateY(20px)' },
    pause: !isIntroFinished ? true : false,
    delay: 300,
    config: { easing: easeOutQuint, duration: 1000 }
  })

  const sloganAppear = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    pause: !isIntroFinished ? true : false,
    delay: 1200,
    //onRest: () => clearLock.current()
    onRest: () => introCleanup()
    //config: { easing: easeOutQuint, duration: 1000 }
  })
  const [ sloganMover, setSlogan ] = useSpring(() => ({
    translateX: '50%'
  }))
  isIntroFinished && setSlogan.start({
    to: { 
      translateX: isBackgroundOpen ? '50%' : '0%',
      //translateX: '-60%'
     },
    //config: { duration: 1000, easing: easeCircleInOut }
    config: { duration: 800, easing: easingTime, precision: 0.001 }
  })



  //1.4 seconds
  //step 1: delay 200, duration 800; step 2: delay 100, duration 800
  //.2 seconds delay
  //1s
  //.2

  const step1Animation = useSpring({
    from: { transform: 'translate(-40%,-20%)', opacity: 0 },
    to: { transform: 'translate(-50%,-50%)', opacity: 1 },
    config: { duration: 800, easing: easeOutQuint },
    delay: 500,
    onRest: () => introAnimationFinished(),
    //onStart: () => introAnimationFinished(),
    
  })
  const step2Animation = useSpring({
    from: { transform: 'translate(-40%,-20%)', opacity: 0 },
    to: { transform: 'translate(-50%,-50%)', opacity: 1 },
    
    config: { duration: 800, easing: easeOutQuint },
    delay: 400,
    //loop: true
  })
  const step3Animation = useSpring({
    from: { transform: 'translate(-40%,-20%)', opacity: 0 },
    to: { transform: 'translate(-50%,-50%)', opacity: 1 },
    config: { duration: 800, easing: easeOutQuint },
    delay: 300,
    onStart: () => beginIntroSetup(),
  })
  






  //const [ isMoving, setIsMoving ] = useState(false)

  //change easing based off isBackgroundOpen

  const [ backgroundStyle, background ] = 
  useSpring(() => ({ 
    //initial settings that will change
    translateX: '0%'
    
   }))

  const [ backgroundPatch, showBackgroundPatch ] = useSpring(()=>({
    opacity: 1 
  }))

  const removeBackgroundPatch = () => showBackgroundPatch.start({
    from: { opacity: 0 },
    to: { opacity: 0 },
    config: { duration: 0 },
  })

  const beginIntroSetup = () => {
    //bring to top, remove background patch, remove scrolling
    window.scrollTo(0,0)
    removeBackgroundPatch()
    //disableBodyScroll(bodyTarget)
    
    //remove scroll functionality

  }

  

  isIntroFinished && background.start({
    to: { 
      translateX: isBackgroundOpen ? '0%' : '-60%',
      //translateX: '-60%'
     },
    //config: { duration: 1000, easing: easeCircleInOut }
    config: { duration: 1000, easing: easingTime, precision: 0.001 }
  })

  //const stairDuration = .667

  return(
    <>
      <animated.div className='backgroundTempBackground' style={{
        ...backgroundPatch,
        display: isIntroFinished ? 'none' : undefined,
      }}></animated.div>

      <animated.div style={ backgroundStyle } className='background'>
        
          <div className='backgroundMainScreen' id='bodyLock' >
            <animated.div className='backgroundLogo' style={ logoDisappear }>
              <img src={ cap } />
              <animated.img src={ step1 } style={ step1Animation } />
              <animated.img src={ step2 } style={ step2Animation } />
              <animated.img src={ step3 } style={ step3Animation } />

          </animated.div>
        </div>
        
      </animated.div>

      <animated.div className='backgroundMainScreen'>
        <div className='contentWrap'>
          <animated.div className='topLogo' style={ logoAppear } >
            <img className='logoSRC' src={ logo } />
          </animated.div>

          <animated.div className='heroSloganBody' style={ sloganAppear } >
            <animated.div className='heroSloganInnerContent' style={ sloganMover }>
              <animated.div className='heroSloganInnerContent2' style={ sloganMover }>
                <div>WHEN</div>
                <div>YOU</div>
                <div>NEED A</div>
                <div className='killerWord'>"KILLER"</div>
                <div>DESIGN...</div>

                <div className='heroButton' onClick={ openHamburger }>Contact Us</div>
              </animated.div>
            </animated.div>
          </animated.div>
        </div>
      </animated.div>
    </>
  )
}

export default memo(Background);