import { useLayoutEffect, memo, useRef, useState } from 'react'

const ScrollAlerter = ({handleBackgroundAlert, isBackgroundOpen, activateMobileArt}) => {
  //console.log('Scroll alerter render')
  
  const backgroundRef = useRef(null)
  backgroundRef.current = isBackgroundOpen

  const sectionTwoMain = useRef(null)
  const isAnimationActive = useRef(false)
 

  const handleScrolling = () => {
    const position = window.pageYOffset;
    //console.log('current position:',position)
    //console.log('window height:',window.innerHeight)
    //console.log('top of section:',sectionTwoMain.current.offsetTop)

    const whitePanelOffset = window.innerHeight * 0.2
    //const whitePanelOffset = 0
    if((window.innerHeight + position) > (sectionTwoMain.current.offsetTop+whitePanelOffset)){
    
      if(!isAnimationActive.current){
        //Section Dos is in view and needs to be activated
        isAnimationActive.current = true
        activateMobileArt('activated')
      }
      }else{
        //Deactivate if not already; out of view
        if(isAnimationActive.current){
          isAnimationActive.current = false
          activateMobileArt('DEactivated')
        }
      }

    if(position > 30 && !backgroundRef.current) handleBackgroundAlert(true)
    if(position < 30 && backgroundRef.current) handleBackgroundAlert(false)
  }
  
  
  useLayoutEffect(() => {
    /*
    window.onbeforeunload = function () {
      
      //window.scrollTo(0, 0);
      //document.querySelector('body').scrollTo(0,0)
      //document.body.scrollTo(0,0);
      //document.getElementById('top').scrollIntoView()
    } */
    
    sectionTwoMain.current = document.getElementById('sectionTwoMain')
    
    
    window.addEventListener('scroll', handleScrolling, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScrolling)
    }
  }, [])

    
  return null
}

export default memo(ScrollAlerter)