import './small.scss';
import Ocean from '../Ocean/Ocean';
import Background from '../Background/Background';
import Section2 from '../Section2/Section2';
//import Section2 from '../Section2/Section2'
import Hamburger from '../Hamburger/Hamburger';
import ScrollAlerter from '../ScrollAlerter/ScrollAlerter';

import { useState, useRef, useLayoutEffect } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

const Small = () => {
  const [isBackgroundOpen, setBackground] = useState(false)

  const [ isIntroFinished, setIntroStatus ] = useState(false)
  const [finalClear, setClear] = useState(false)
  const [updateBurger, setBurger] = useState(false)
  const [ backgroundStatus, setStatus ] = useState('initial')

  const burgerTracker = useRef(null)
  const backgroundTracker = useRef(false)
  const introTracker = useRef(false)

  const introFinished = useRef(()=>{
    introTracker.current = true
    if(!isIntroFinished) setIntroStatus(true)
  })

  //updateBurger state needs to be tracked w/ref so ref functions have latest value
  useLayoutEffect(() => {
    if(burgerTracker.current !== updateBurger) burgerTracker.current = updateBurger
    if(backgroundTracker.current !== isBackgroundOpen) backgroundTracker.current = isBackgroundOpen
  })

  const openBurger = useRef(()=>{
    //console.log('clicky clik', updateBurger)
    setBurger(!burgerTracker.current)
  })
  
  const introCleanup = useRef(()=>{
    //add scrollbar back
    //restore scroll body
    
    clearAllBodyScrollLocks()
    setClear(!finalClear)
  })

  const mobileArt = useRef((status) => {
    introTracker.current && setStatus(status)
  })

  const handleBackgroundAlert = useRef((status) => {
    //console.log('status', status)
    if(backgroundTracker.current && !status) setBackground(false)
    if(!backgroundTracker.current && status) setBackground(true)
  })

  return(
    <div className='heroMainBody'>
      {/* !finalClear &&
        <RemoveScrollBar />
      */}
      <ScrollAlerter handleBackgroundAlert={ handleBackgroundAlert.current } isBackgroundOpen={ isBackgroundOpen } activateMobileArt={ mobileArt.current } />
      <Ocean isIntroFinished={ isIntroFinished } />
      
      <Background 
        isBackgroundOpen={ isBackgroundOpen } 
        introAnimationFinished={ introFinished.current } 
        isIntroFinished={ isIntroFinished } 
        openHamburger={ openBurger.current } 
        introCleanup={ introCleanup.current }
      />

      <Section2 activateArt={ backgroundStatus } openTheBurger={ openBurger.current } />
      <Hamburger isIntroFinished={ isIntroFinished } updateBurger={ updateBurger } resetBurgerVar={ openBurger.current } />
    </div>
  )
};

export default Small;