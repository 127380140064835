
const LargeBackground = () => {
  return(
    <svg version="1.1" id="theOcean" preserveAspectRatio='none' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 1872 1872">
      <g>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="936" y1="1872" x2="936" y2="-9.094947e-13">
          <stop  offset="0" style={{stopColor: '#005DE9'}} />
          <stop  offset="0.0934" style={{stopColor: '#1070EF'}} />
          <stop  offset="0.3665" style={{stopColor: '#3FA6FF'}} />
          <stop  offset="0.5792" style={{stopColor: '#6AC2FF'}} />
          <stop  offset="1" style={{stopColor: '#96DAFF'}} />
        </linearGradient>
        <rect className="st0" width="1872" height="1872"/>
      </g>
    </svg>
  );
};

export default LargeBackground;