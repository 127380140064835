import './largeOcean.scss';
import SmallWhaleLargeOcean from './SmallWhaleLargeOcean/SmallWhaleLargeOcean';
import LargeWhaleLargeOcean from './LargeWhaleLargeOcean/LargeWhaleLargeOcean';
import LargeBackground from './LargeBackground/LargeBackground';
import sunRays from '../Ocean/Sun Rays.svg';

import { memo } from 'react';
import { animated, useSpring } from 'react-spring';

const LargeOcean = ({isIntroFinished}) => {
  const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2)
  const [ sunRayAnimation, setSunRay ] = useSpring(()=>({
    rotate: '0deg', transformOrigin: 'top right'
  }))

  const sunRayStart = () => setSunRay.start({
    from: { rotate: '0deg', transformOrigin: 'top right' },
    to: { rotate: '8deg', transformOrigin: 'top right' },
    config: { duration: 4000, easing: easeInOutQuad },
    onRest: () => sunRayT1()
  })
  const sunRayT1 = () => setSunRay.start({
    from: { rotate: '8deg', transformOrigin: 'top right' },
    to: { rotate: '0deg', transformOrigin: 'top right' },
    config: { duration: 4000, easing: easeInOutQuad },
    onRest: () => sunRayStart()
  })

  isIntroFinished && sunRayStart()

  return(
    <div className='oceanDesktopMain'>
      <LargeBackground />
      <SmallWhaleLargeOcean />

      <animated.img 
        className='sunRayDesktop' 
        src={ sunRays } 
        style={ sunRayAnimation } 
      />
      <LargeWhaleLargeOcean isIntroFinished={isIntroFinished} />
      
    </div>
  )
};

export default memo(LargeOcean);