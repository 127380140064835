import './frontSlider.scss';
import LargeOcean from '../LargeOcean/LargeOcean';
import cap from '../Background/Cap.svg';
import step1 from '../Background/Step1.svg';
import step2 from '../Background/Step2.svg';
import step3 from '../Background/Step3.svg';
import oceanPlain from '../Ocean/OceanPlain.svg';

import { memo, useLayoutEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const FrontSlider = ({introIsFinished, isIntroFinished}) => {

  //const easeOutSine = x => (Math.sin((x * Math.PI) / 2))
  const easeOutCubic = x => (1 - Math.pow(1 - x, 3))
  const easeInOutCubic = x => (x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2)
  const easeOutQuint = (x) => (1 - Math.pow(1 - x, 5))
  
  const sliderContent = useRef(null)
  useLayoutEffect(() => {
    sliderContent.current = document.getElementById('sliderContent')
    disableBodyScroll(sliderContent.current, { reserveScrollBarGap: true })
    return () => {
      clearAllBodyScrollLocks()
    };
  }, [])

  const turnOffScrollLock = useRef(() => {
    clearAllBodyScrollLocks()
  })

  const logoTextIntro = useSpring({
    from: { translateY: '100%' },
    to: { translateY: '0%' },
    config: { duration: 900, easing: easeOutQuint },
    pause: isIntroFinished ? false : true,
    delay: 800,
    onStart: () => turnOffScrollLock.current()
  })

  

  const sliderAnimations = useSpring({
    from: { translateX: '0%' },
    to: { translateX: '-40%' },
    config: { duration: 700, easing: easeInOutCubic },
    //delay: 100,
    pause: isIntroFinished ? false : true
  })
  const wrapSlider = useSpring({
    from: { translateX: '0%' },
    to: { translateX: '20%' },
    config: { duration: 700, easing: easeInOutCubic },
    //delay: 100,
    pause: isIntroFinished ? false : true
  })
  const bringLogoUp = useSpring({
    from: { translateY: '0%' },
    to: { translateY: '-30%' },
    config: { duration: 400, easing: easeInOutCubic },
    pause: isIntroFinished ? false : true,
    delay: 600,
  })

  const step1Animation = useSpring({
    from: { transform: 'translate(-40%,-20%)', opacity: 0 },
    to: { transform: 'translate(-50%,-50%)', opacity: 1 },
    config: { duration: 800, easing: easeOutQuint },
    delay: 500,
    
    //onStart: () => introIsFinished(),
    onRest: () => introIsFinished(),
  })
  const step2Animation = useSpring({
    from: { transform: 'translate(-40%,-20%)', opacity: 0 },
    to: { transform: 'translate(-50%,-50%)', opacity: 1 },
    
    config: { duration: 800, easing: easeOutQuint },
    delay: 400,
    //loop: true
  })
  const step3Animation = useSpring({
    from: { transform: 'translate(-40%,-20%)', opacity: 0 },
    to: { transform: 'translate(-50%,-50%)', opacity: 1 },
    config: { duration: 800, easing: easeOutQuint },
    delay: 300,
    onStart: () => beginIntroSetup(),
  })

  const removeInitialOceanCover = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    pause: !isIntroFinished ? true : false,
    delay: 800,
    config: { duration: 800 }
  })

  const [ backgroundPatch, showBackgroundPatch ] = useSpring(()=>({
    opacity: 1 
  }))

  const removeBackgroundPatch = () => showBackgroundPatch.start({
    from: { opacity: 0 },
    to: { opacity: 0, },
    config: { duration: 0 },
  })

  const beginIntroSetup = () => {
    //bring to top, remove background patch, remove scrolling(before this, initially)
    
    window.scrollTo(0,0)
    removeBackgroundPatch()
    
  }
  
  
  //
  return(
    <div className='sliderMain'>
      <animated.div className='sliderContent' id='sliderContent' >
        <div className='oceanWrap'>
          <LargeOcean isIntroFinished={isIntroFinished} />
        </div>
        <animated.div className='initialGrey' style={ backgroundPatch }></animated.div>
        <animated.img className='blueBackground' src={ oceanPlain } style={ removeInitialOceanCover } />
        <animated.div className='leftSide' style={ sliderAnimations }>
          <div className='leftSideAdjuster'>
            <animated.div className='leftSideInnerWrap' style={ wrapSlider }>
              <animated.div className='logoWrap'>
                <animated.div className='logoInnerContent' style={ bringLogoUp }>
                  <animated.img src={ cap } />
                  <animated.img src={ step1 } style={ step1Animation } />
                  <animated.img src={ step2 } style={ step2Animation } />
                  <animated.img src={ step3 } style={ step3Animation } />
                </animated.div>
              </animated.div>
              <animated.div className='logoText' >
                <div className='logoTextWrapper'>
                  <animated.div style={logoTextIntro}>WHEN YOU NEED</animated.div>
                </div>
                <div className='logoTextWrapper'>
                  <animated.div style={logoTextIntro}>A <span>"KILLER"</span> DESIGN...</animated.div>
                </div>
              </animated.div>
            </animated.div>
          </div>
        </animated.div>
      </animated.div>
    </div>
  )
}


export default memo(FrontSlider)